import {
  Box,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { Helmet } from 'react-helmet';
import SearchIcon from '@mui/icons-material/Search';

export default function PageNotFound() {
  const navigate = useNavigate();
  const handleNavigate = (route: string) => navigate(`${route}`);

  return (
    <>
      <Helmet>
        <title>Page Not Found | Safe Haven Security</title>
        <meta name="description" content="Learn about Safe Haven Strong, your trusted provider for home security solutions." />
        <meta name="keywords" content="home security, ADT authorized dealer, Safe Haven Strong" />
      </Helmet>
      <Box sx={{ backgroundColor: '#ffffff', width: '100%' }}>
        <Container>
          <Stack direction={'column'} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', padding: "70px 0" }}>
            <Typography sx={{ fontWeight: '700', fontSize: '48px', maxWidth: '600px', textAlign: 'center', marginBottom: '24px' }}>
              The page you’re looking for can’t be found.
            </Typography>
            {/* <TextField
              label=''
              className='site-search-input'
              placeholder='Search mysafehaven.com'
              sx={{
                minWidth: { xs: '100%', sm: '500px' }, color: '#86868b', borderColor: '#86868b', borderRadius: '12px',
                '& .MuiInputBase-root': {
                  borderRadius: '12px',
                },
              }}
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: '#86868b', marginRight: '10px' }}></SearchIcon>,
              }}
            /> */}
          </Stack>
        </Container>
      </Box>
    </>
  );
}
