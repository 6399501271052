import React, { useEffect } from 'react';
import Home from './Pages/Home';
import Careers from './Pages/Careers';
import Products from './Pages/Products';
import { Routes, Route, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Footer from './Components/Footer/';
import Header from './Components/Header';
import { useAppSelector } from './App/hooks';
import Partners from './Pages/Partners';
import AgentReferral from './Pages/Partners/AgentReferral';
import Contact from './Pages/Contact';
import CustomerSolutions from './Pages/CustomerSolutions';
import CustomerSupport from './Pages/CustomerSupport';
import HomebuilderPartners from './Pages/Partners/Homebuilder';
import Legal from './Pages/Legal';
import Privacy from './Pages/Privacy';
import Solar from './Pages/Solar';
import TermsAndConditionsADT from './Pages/TermsAndConditionsADT';
import AboutUs from './Pages/AboutUs';
import RemodeledHomeBuilder from './Pages/Remodel/Partners/Home-builder';
import RemodeledHome from './Pages/Remodel/Home';
import RemodeledCareers from './Pages/Remodel/Careers';
import RemodeledProducts from './Pages/Remodel/Products';
import RemodeledContactUs from './Pages/Remodel/ContactUs';
import RemodeledAboutUs from './Pages/Remodel/AboutUs';
import RemodeledPartners from './Pages/Remodel/Partners';
import RemodeledAgentReferral from './Pages/Remodel/Partners/AgentReferral';
import RemodeledApplyNow from './Pages/Remodel/Careers/ApplyNow';
import TermsAndConditionsSH from './Pages/TermsAndConditionsSH';
import Affirmation from './Pages/Affirmation';
import DocumentDownloader from './Components/DocumentDownloader';
import RealtorTeam from './Pages/RealtorTeam';
import Agents from './Pages/RealtorTeam/Agents';
import Individual from './Pages/RealtorTeam/Agents/Individual';
import HomeIsConnected from './Pages/HomeIsConnected';
import InsideSalesManagerOverview from './Pages/InsideSalesManagerOverview';
import SalesManagerOverview from './Pages/SalesManagerOverview';
import PageNotFound from './Pages/PageNotFound';
import SiteMap from './Pages/SiteMap';
import CandidateVideo from './Pages/CandidateVideo';
import Gtr from './Pages/Gtr';
import PartnerStackLegal from './Pages/PartnerStackLegal';
import LandingPage from './Pages/Partners/Affiliates';
import Nerdwallet from './Pages/Partners/Affiliates/Templates/7';
import { redirect } from 'react-router-dom';
import NerdWalletLandingPage from './Pages/Partners/NerdWallet';
import { HIDE_FOOTER_PATHS, HIDE_HEADER_PATHS } from './Constants';
import CenturyModel from './Pages/Partners/Affiliates/CenturyModel';
import DRHModel from './Pages/Partners/Affiliates/DRHModel';
import AllAccessService from './Pages/Partners/Affiliates/Templates/6';
import ConfirmationPage from './Pages/Partners/Affiliates/ConfirmationPage';

export default function Router() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const alertBannerDisplay = useAppSelector(
    (state) => state.alertBanner.display
  );

  const hideFooter = HIDE_FOOTER_PATHS.includes(pathname.toLowerCase()) ||
    pathname.toLowerCase().match(/^\/(partners|nerdwallet)\//);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log('pathname', pathname);
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (pathname.endsWith('/') && pathname !== '/') {
      navigate(pathname.slice(0, -1), { replace: true });
    }
  }, [pathname, navigate]);

  return (
    <main>
      {HIDE_HEADER_PATHS.includes(pathname) ? null : <Header></Header>}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/candidate-video' element={<CandidateVideo />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='/careers/realtor-team' element={<RealtorTeam />} />

        <Route path='/realtor-team/agents' element={<Agents />} />
        <Route path='/realtor-team/agents/:AgentName' element={<Individual />} />
        <Route
          path="/alex-rounds"
          element={<Navigate to="/realtor-team/agents/alex-rounds" replace />}
        />
        <Route
          path="/russell-loomis"
          element={<Navigate to="/realtor-team/agents/russell-loomis" replace />}
        />

        <Route path='/homeisconnected' element={<HomeIsConnected />} />
        <Route path='/insidesalesmanageroverview' element={<InsideSalesManagerOverview />} />
        <Route path='/salesmanageroverview' element={<SalesManagerOverview />} />

        <Route path='/contact' element={<Contact />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='/customer-solutions' element={<CustomerSolutions />} />
        <Route path='/customer-support' element={<CustomerSupport />} />

        <Route path='/partners/all-access-service' element={<AllAccessService />} />
        <Route path='/partners' element={<Partners />} />
        <Route path='/partners/agent-referral' element={<AgentReferral />} />
        <Route path='/partners/homebuilder-partners' element={<HomebuilderPartners />} />
        <Route
          path='/partners/century-model'
          element={<CenturyModel />}
        />
        <Route
          path='/partners/drh-model'
          element={<DRHModel />}
        />
        <Route path='/partners/confirmation' element={<ConfirmationPage />} />
        <Route path='/partners/:partnerId/:partnerCampaignId' element={<LandingPage />} />
        <Route path='/partners/:pagePath' element={<LandingPage />} />
        <Route path='/nerdwallet' element={<NerdWalletLandingPage />} />
        <Route path='/nerdwallet/:campaignid?/:clickid?' element={<NerdWalletLandingPage />} />


        <Route path="/legal">
          <Route index element={<Legal />} /> {/* Default for /legal */}
          <Route path="privacy" element={<Privacy />} />
          <Route path="affirmation" element={<Affirmation />} />
          <Route path="gtr" element={<Gtr />} />
          <Route path="partnerstack-terms-conditions-for-safe-haven" element={<PartnerStackLegal />} />
          <Route path="adt-terms-and-conditions" element={<TermsAndConditionsADT />} />
          <Route path="terms-and-conditions" element={<TermsAndConditionsADT />} /> {/* Note potential duplication */}
          <Route path="safe-haven-terms-and-conditions" element={<TermsAndConditionsSH />} />
        </Route>
        <Route path='/affirmation' element={<Affirmation />} />{' '}
        <Route
          path='/safe-haven-terms-and-conditions'
          element={<TermsAndConditionsSH />}
        />
        <Route
          path='/terms-and-conditions/'
          element={<TermsAndConditionsADT />}
        />

        <Route path='/products' element={<Products />} />
        <Route path='/solar' element={<Solar />} />

        {/* Remodel */}
        <Route path='/remodel/' element={<RemodeledHome />} />
        <Route path='/remodel/careers' element={<RemodeledCareers />} />
        <Route path='/remodel/products' element={<RemodeledProducts />} />
        <Route path='/remodel/about-us' element={<RemodeledAboutUs />} />
        <Route path='/remodel/contact-us' element={<RemodeledContactUs />} />
        <Route path='/remodel/partners' element={<RemodeledPartners />} />
        <Route
          path='/remodel/partners/agent-referral'
          element={<RemodeledAgentReferral />}
        />
        <Route
          path='/remodel/partners/home-builder'
          element={<RemodeledHomeBuilder />}
        />
        <Route
          path='/remodel/applynow/:jobId'
          element={<RemodeledApplyNow />}
        />
        <Route path='/remodel/sitemap' element={<></>} />

        {/* <Route path='/sitemap' element={<SiteMap></SiteMap>} /> */}
        <Route path="*" element={<PageNotFound />}></Route>
      </Routes>
      <Outlet />

      {!hideFooter && <Footer></Footer>}
    </main>
  );
}
