import { Helmet } from 'react-helmet';
import { Box, Container, Stack } from '@mui/system';
import { Button, FormHelperText, Grid, Link, TextField, Typography } from '@mui/material';
import nraLogo from "../../../../../Assets/Partner/NRA/NRA-logo-300x168.png";
import blueGradient from "../../../../../Assets/Partner/NRA/Blue-gradient-1274x509.png";
import flagBg from "../../../../../Assets/Partner/NRA/Flag-graphic-1274x509.png";
import goldVoucher from "../../../../../Assets/Partner/NRA/Gold-voucher.png";
import CompanyLogo from '../../../../../Components/Header/CompanyLogo';
import panelPic from "../../../../../Assets/Partner/NRA/IQ-Panel-Hero-pic.png";
import quadPic from "../../../../../Assets/Partner/NRA/Photo-section_cropped.png";
import iconsAndText from "../../../../../Assets/Partner/NRA/Icons-and-text.png";
import "./index.scss";
import * as Yup from 'yup'; // For form validation
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import useFetch from 'use-http';
import PartnerLogo from '../../../../../Components/PartnerLogo';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoBanner from '../../Components/LogoBanner';
import SplitBlock from '../../Components/SplitBlock';
import CircularProgress from '@mui/material/CircularProgress';

interface propsWrapper {
    partnerInfo: PartnerInfo
}

export default function TemplateThree(props: propsWrapper) {
    const navigate = useNavigate();
    const { partnerInfo } = props;
    const formRef = useRef<HTMLDivElement>(null);
    const [leadCreationStatus, setLeadCreationStatus] = useState<boolean | null>(null);

    useEffect(() => {
        console.log(partnerInfo)
    }, []);

    let apiKey = process.env.REACT_APP_API__ACCESS_KEY
        ? process.env.REACT_APP_API__ACCESS_KEY
        : '';
    const options = {
        headers: {
            'Content-Type': 'application/json',
            Connection: 'keep-alive',
            'Accept-Encoding': 'gzip, deflate, br',
            'X-API-Key': apiKey,
        },
        cacheLife: 1000,
    };

    const { post, response, loading } = useFetch(
        process.env.REACT_APP_API,
        options
    );

    const initialValues = {
        firstName: '',
        zip: '',
        phone: '',
        email: '',
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required('Required')
            .min(2, 'First name must be at least 2 characters')
            .max(50, 'First name must be at most 50 characters')
            .matches(/^[a-zA-Z '-]+$/, 'First name can only contain letters, hyphens, apostrophes, and spaces'),
        zip: Yup.string()
            .required('Required')
            .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Invalid zip code format'),
        phone: Yup.string().required('Required')
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
    });

    const handleScrollToForm = () => {
        if (formRef.current) {
            const elementTop = formRef.current.getBoundingClientRect().top;
            const offsetPosition = elementTop + window.pageYOffset - (window.innerHeight * 0.2);

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    const openInNewTab = (url: string) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <Box id='template3-wrapper'>

            <LogoBanner partnerInfo={partnerInfo}></LogoBanner>

            <SplitBlock
                backgroundImage={blueGradient}
                leftContent={
                    <>
                        <Typography className='blue-overlay-text1'>Helping to keep your home & family safe even when you’re not there</Typography>
                        <Typography className='blue-overlay-text2'>Safe Haven offers a unique opportunity for NRA members, empowering them to protect their home and loved ones with ADT’s renowned security monitoring service, the most trusted name in security.</Typography>
                        <Typography className='blue-overlay-text2'>This comprehensive security solution not only provides peace of mind by safeguarding your home but also integrates smart home features for added convenience.</Typography>
                    </>
                }
                rightImage={panelPic}
                onButtonClick={handleScrollToForm}
            />

            <Container maxWidth={false} className='flag-bg' sx={{ padding: '0!important', maxWidth: '1500px' }}>
                <Box
                    component="img"
                    sx={{
                        minHeight: "600px",
                        maxWidth: "100%",
                        height: "auto",
                        width: "100%"
                    }}
                    src={flagBg}
                />

                <Grid container className="flag-bg-overlay" sx={{ padding: { xs: '0' }, }}>
                    <Stack sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: "100%",
                        maxWidth: { xs: '100%', md: '950px' },
                    }}>
                        <Typography className='flag-text-header'>Get a security system starting at $55.99/mth</Typography>
                        <Typography className='flag-text-sub-header'>As an NRA member, you may also qualify for a voucher up to $500* for customizing your system with additional equipment.</Typography>
                        <Box
                            component="img"
                            sx={{
                                maxWidth: { xs: '90%', md: "630px" },
                                height: "auto",
                            }}
                            src={goldVoucher}
                        />
                        <Typography className='flag-text-disclaimer'>
                            *With $99.00 Customer Installation Charge and purchase of Remote alarm monitoring services package.
                            Specific equipment and pricing will vary by package. Early termination fees apply. See important Terms and
                            Conditions to this offer <Link
                                sx={{ textDecoration: "underline" }}
                                href="https://mysafehaven.com/terms-and-conditions"
                                onMouseDown={(event) => {
                                    if (event.button === 1) {
                                        openInNewTab(`https://mysafehaven.com/terms-and-conditions`);
                                    }
                                }}>
                                here
                            </Link>.
                        </Typography>
                    </Stack>
                </Grid>
            </Container>

            <Box sx={{ bgcolor: 'rgb(237, 237, 237)', padding: '5vh 0' }}>
                <Stack sx={{ maxWidth: '1024px', margin: 'auto' }}>
                    <Box sx={{ color: '#545859', textAlign: 'center', margin: { xs: '32px auto 0px auto', md: '32px auto 16px auto' } }}>
                        <Typography sx={{ font: { xs: 'normal 800 28px/38px "Montserrat",Helvetica,Arial,Verdana,sans-serif', md: 'normal 800 44px/54px "Montserrat",Helvetica,Arial,Verdana,sans-serif' }, marginBottom: '10px' }}>
                            Get a FREE quote
                        </Typography>
                        <Typography className='form-call-text' sx={{ font: 'normal 500 20px/30px "Montserrat",Helvetica,Arial,Verdana,sans-serif', maxWidth: { xs: '750px', lg: 'none' } }}>
                            Call <Link href="tel:8778502088" underline="none">1 (877) 355-9957</Link> or fill out the form below and a Safe Haven Security Specialist will contact you, from time to time, about security and smart home offers.
                        </Typography>
                    </Box>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            try {
                                const cleansedPhoneNumber = values.phone.replace(/\D/g, '');

                                // Add the hidden values to the submission data
                                const submissionData = {
                                    ...values,
                                    phone: cleansedPhoneNumber,
                                    partnerCompanyId: partnerInfo.PartnerId,
                                    partnerCampaignId: partnerInfo.CampaignId,
                                };

                                const postResponse: ApiResponseModel = await post("/Partner/CreateZohoLeadByPartnerId", submissionData);

                                if (postResponse.code === 'OK') {
                                    const parsedContent = JSON.parse(postResponse.content);

                                    const newLeadId = parsedContent.details.output;
                                    const userMessage = JSON.parse(parsedContent.details.userMessage[0]);

                                    setLeadCreationStatus(true);

                                    console.log("New ID: " + newLeadId);

                                    navigate('/partners/confirmation', { state: { newLeadId, partnerInfo } });
                                } else {
                                    console.log("Response code not OK: " + postResponse.code);
                                    setLeadCreationStatus(false);
                                }

                                console.log("Request sent successfully:", postResponse);
                                resetForm();
                            } catch (error) {
                                console.error("Error sending request:", error);
                            } finally {
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({ errors, touched, values, setFieldValue, isSubmitting }) => ( // Access Formik state here
                            <Form>
                                <Box className='fields-container freedom-mortgage nra-fields-wrapper' ref={formRef}>
                                    <Field type="hidden" name="partnerCompanyId" />
                                    <Field type="hidden" name="PartnerCampaignId" />
                                    <Field
                                        as={TextField}
                                        className='quarter-width left-field freedom-mortgage-field nra-form-field'
                                        name="firstName"
                                        label="First Name *"
                                        value={values.firstName}
                                        onChange={(event: any) => {
                                            setFieldValue("firstName", event.target.value);
                                        }} error={touched.firstName && Boolean(errors.firstName)}
                                        helperText={touched.firstName && errors.firstName && (
                                            <div key={`error-firstName`}>{errors.firstName}</div>
                                        )}
                                    />

                                    <Field
                                        as={TextField}
                                        className='quarter-width left-field right-field freedom-mortgage-field nra-form-field'
                                        name="email"
                                        label="Email *"
                                        value={values.email}
                                        onChange={(event: { target: { value: any; }; }) => setFieldValue("email", event.target.value)}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                    />

                                    <Field
                                        as={TextField}
                                        className='quarter-width left-field right-field freedom-mortgage-field nra-form-field'
                                        name="phone"
                                        label="Phone Number *"
                                        value={values.phone}
                                        onChange={(event: { target: { value: any; }; }) => {
                                            const inputValue = event.target.value.replace(/\D/g, '');

                                            let formattedValue = '';
                                            if (inputValue.length > 0) {
                                                formattedValue = '(' + inputValue.substring(0, 3);
                                                if (inputValue.length > 3) {
                                                    formattedValue += ') ' + inputValue.substring(3, 6);
                                                }
                                                if (inputValue.length > 6) {
                                                    formattedValue += '-' + inputValue.substring(6, 10);
                                                }
                                            }

                                            setFieldValue('phone', formattedValue);
                                        }}
                                        onPaste={(event: { clipboardData: any; preventDefault: () => void; }) => {
                                            const clipboardData = event.clipboardData;
                                            const pastedData = clipboardData.getData('text');
                                            const cleanedData = pastedData.replace(/\D/g, '');
                                            const formattedValue = cleanedData.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                            setFieldValue('phone', formattedValue);
                                            event.preventDefault();
                                        }}
                                        error={touched.phone && Boolean(errors.phone)}
                                        helperText={touched.phone && errors.phone}
                                    />

                                    <Field
                                        as={TextField}
                                        className='quarter-width right-field freedom-mortgage-field nra-form-field'
                                        name="zip"
                                        label="Zip Code *"
                                        value={values.zip}
                                        onChange={(event: { target: { value: any; }; }) => setFieldValue("zip", event.target.value)}
                                        error={touched.zip && Boolean(errors.zip)}
                                        helperText={touched.zip && errors.zip}
                                    />

                                    <Box sx={{ width: "100%!important", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography sx={{
                                            textAlign: 'center',
                                            fontSize: '12px',
                                            lineHeight: '16px',
                                            maxWidth: '950px',
                                            margin: '16px auto auto auto',
                                        }}>
                                            By clicking ‘Get A Free Quote,’ I consent to being contacted by Safe Haven Security Services, LLC, Safe Haven Solar, LLC, or All Access Service, LLC regarding products and services via live, automated, or prerecorded telephone calls, text messages*, or emails, even if I’m on a Do Not Call list. Consent is not a condition of purchase. I can revoke this consent by emailing dnc@mysafehaven.com.
                                        </Typography>
                                        <LoadingButton
                                            sx={{
                                                width: '170px',
                                                height: '40px',
                                                fontSize: '14px',
                                                lineHeight: '16px',
                                                padding: '0!important',
                                                transition: 'none'
                                            }}
                                            variant='contained'
                                            className='nra-submit-button'
                                            type="submit"
                                            loading={isSubmitting}
                                            disabled={isSubmitting}>
                                            {isSubmitting ? 'Submitting...' : 'GET A FREE QUOTE'}
                                        </LoadingButton>
                                        <Typography sx={{
                                            textAlign: 'center',
                                            fontSize: '12px',
                                            lineHeight: '16px',
                                            maxWidth: '950px',
                                            margin: '16px auto auto auto',
                                        }}>
                                            * Standard message and data rates may apply to text messages. You may opt out of receiving further text messages at any time by replying STOP.
                                            <br></br>
                                            † Your information is collected and used in accordance with Safe Haven’s {" "}
                                            <Link
                                                sx={{ textDecoration: "underline" }}
                                                href="https://mysafehaven.com/legal/privacy"
                                                onMouseDown={(event) => {
                                                    if (event.button === 1) {
                                                        openInNewTab(`https://mysafehaven.com/legal/privacy`);
                                                    }
                                                }}>
                                                Privacy Policy
                                            </Link>.
                                        </Typography>
                                    </Box>

                                    {/* {Object.keys(errors).length > 0 && (
                                    <div className="error-summary">
                                        <p>Please correct the following errors:</p>
                                        <ul>
                                            {Object.values(errors).map((error) => (
                                                <li key={error}>{error}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )} */}
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Stack>
            </Box>


            <Container maxWidth={false} className='container-1500' sx={{ padding: { xs: 0, md: 'initial' }, marginTop: '36px' }}>
                <Box
                    component="img"
                    sx={{
                        width: '100%',
                        maxWidth: "100%",
                        height: "auto",
                    }}
                    src={quadPic}
                />
                <Box
                    component="img"
                    sx={{
                        width: '100%',
                        maxWidth: "100%",
                        height: "auto",
                    }}
                    src={iconsAndText}
                />
                <Box className='page-disclaimer' sx={{ padding: { xs: "32px 16px", md: "64px 0 64px 0" } }}>
                    <Typography className='page-disclaimer-text-1'>
                        No NRA member dues or contributions are used for this promotion, program, or any other related expenses.
                    </Typography>
                    <Typography className='page-disclaimer-text-2'>
                        Dealer License Numbers by State: AL-1493, AR- CMPY.0002629, AZ- ROC-332758,18404-0, CA-ACO8016, CT-HIC.0661825, DC-602521000002,
                        DE-17-39, FL-EF20000905, GA-LVU406182, IA-AS-0158, C115438, IL-127.001386, LA-F1672, MA-8150 A1, SS-002127, MD-107-1696, MI-3601208054, MN-TS710056,
                        MS-15019531, NC-1150-CSA, SP.FA/LV.28805, NH-064, NJ- 34BX00006600, NM-403946, NV-0079533, NY-12000077734, OK-AC1802, OR-234026, PA-PA040539, RI-4604, TSC8204, SC-BAC.13574, TN-2280,
                        TX-B14839,ACR-2625584, UT-10999117-6501, VA-11-6402, WA-SAFEHHS791CH, WV-WV060562
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}
