import { Helmet } from 'react-helmet';
import { Box, Container, Stack } from '@mui/system';
import { Button, Grid, Link, TextField, Typography } from '@mui/material';
import partnerLogo from "../../../../../Assets/Partner/FreedomMortgage/Freedom-logo-Blue-writing-1-300x90-1.png";
import equipmentBg from "../../../../../Assets/Partner/FreedomMortgage/New-equipment-layout_750-voucher-scaled-e1631196825855.jpg";
import voucher750 from "../../../../../Assets/Templates/750-Gift-card.png";
import equipmentBgCropped from "../../../../../Assets/Partner/FreedomMortgage/New-equipment-layout_750-voucher-scaled-cropped.jpg";
import equipmentBgCroppedVert from "../../../../../Assets/Partner/FreedomMortgage/New-equipment-layout_750-voucher-scaled-e1631196825855-vertical-crop.jpg";
import familyPic from "../../../../../Assets/Partner/FreedomMortgage/Stock_HappyFamilyHouseADTsign.png";
import installTech from "../../../../../Assets/Partner/FreedomMortgage/Install-Tech.jpg";
import remote from "../../../../../Assets/Partner/FreedomMortgage/Remote.jpg";
import control from "../../../../../Assets/Partner/FreedomMortgage/Control.jpg";
import automation from "../../../../../Assets/Partner/FreedomMortgage/Video-Home-Automation.jpg";
import freedomLogo from "../../../../../Assets/Partner/FreedomMortgage/Freedom-logo-Blue-writing-1-300x90.png";
import * as Yup from 'yup'; // For form validation
import "./index.scss";
import CompanyLogo from '../../../../../Components/Header/CompanyLogo';
import GoogleReviews from '../../../../../Components/GoogleReviews';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import useFetch from 'use-http';
import PartnerLogo from '../../../../../Components/PartnerLogo';
import { BUTTON_LOGO_BANNER, HIDE_ADRESS_FIELD, HIDE_PARTNER_LOGO, SHOW_AGENT_NAME, SHOW_CRM_ID } from '../../Constants';
import { createTheme } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LogoBanner from '../../Components/LogoBanner';
import EquipmentOverlayBillboard from '../../Components/EquipmentOverlayBillboard';

interface propsWrapper {
    partnerInfo: PartnerInfo
}

export default function TemplateTwo(props: propsWrapper) {
    const { pathname } = useLocation();
    const { partnerInfo } = props;
    const navigate = useNavigate();
    const handleNavigate = (route: string) => navigate(`${route}`);
    const topFormRef = useRef<HTMLDivElement>(null);
    const bottomFormRef = useRef<HTMLDivElement>(null);
    const [isLgScreen, setIsLgScreen] = useState(window.innerWidth > 1200);
    const [showButton, setShowButton] = useState(false);
    const SHOW_SCROLL_BUTTON_THRESHOLD = 300;
    const [logoBannerType, setLogoBannerType] = useState('default');
    const [phoneNumber, setPhoneNumber] = useState('+1 (877) 664-0673');
    const [buttonColor, setButtonColor] = useState('green');
    const [leadCreationStatus, setLeadCreationStatus] = useState<boolean | null>(null);

    useEffect(() => {
        const handleResize = () => {
            requestAnimationFrame(() => setIsLgScreen(window.innerWidth > 1200));
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const scrollListener = () => {
            setShowButton(window.scrollY > SHOW_SCROLL_BUTTON_THRESHOLD);
        };

        window.addEventListener('scroll', scrollListener);
        return () => window.removeEventListener('scroll', scrollListener);
    }, []);

    useEffect(() => {
        if (BUTTON_LOGO_BANNER.includes(pathname)) {
            setLogoBannerType('logoButton');
        } else if (HIDE_PARTNER_LOGO.includes(pathname)) {
            setLogoBannerType('button');
        }
        console.log(partnerInfo);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleScrollToBottomForm = () => {
        if (bottomFormRef.current) {
            const elementTop = bottomFormRef.current.getBoundingClientRect().top;
            const offsetPosition = elementTop + window.pageYOffset - (window.innerHeight * 0.2);

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    // Formik Initial Values and Validation Schema
    const initialValues = {
        firstName: '',
        lastName: '',
        address: '',
        zip: '',
        phone: '',
        email: '',
        customerNotes: '',
        agentName: '',
        crmId: '',
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required('Required')
            .min(2, 'First name must be at least 2 characters')
            .max(50, 'First name must be at most 50 characters')
            .matches(/^[a-zA-Z '-]+$/, 'First name can only contain letters, hyphens, apostrophes, and spaces'),
        lastName: Yup.string().required('Required'),
        address: Yup.string().when('campaignId', {
            is: "2751",
            then: () => Yup.string().nullable(),
            otherwise: () => Yup.string().required('Required')
        }),
        Zip: Yup.string().matches(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code format').required('Required'),
        phone: Yup.string().required('Required')
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        customerNotes: Yup.string(),
        agentName: Yup.string().when('campaignId', {
            is: "3733",
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        crmId: Yup.string().when('campaignId', {
            is: "3733" || "2913" || "2604" || "3007",
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
    });

    let apiKey = process.env.REACT_APP_API__ACCESS_KEY
        ? process.env.REACT_APP_API__ACCESS_KEY
        : '';

    const options = {
        headers: {
            'Content-Type': 'application/json',
            Connection: 'keep-alive',
            'Accept-Encoding': 'gzip, deflate, br',
            'X-API-Key': apiKey,
        },
        cacheLife: 1000,
    };

    const { post, response, loading } = useFetch(
        process.env.REACT_APP_API,
        options
    );

    return (
        <>
            <Box id='template2-wrapper' sx={{ maxWidth: '100%' }}>
                {showButton && (
                    <Box className='scroll-top-button' onClick={handleScrollToTop}>
                        <ArrowUpwardIcon className='arrow-icon'></ArrowUpwardIcon>
                    </Box>
                )}

                <Box sx={{ margin: '16px' }}>
                    <LogoBanner partnerInfo={partnerInfo} variant={logoBannerType} phoneNumber={phoneNumber} buttonColor={buttonColor} quoteButtonText={'Call 877-526-0684'} numberLink={false}></LogoBanner>
                </Box>

                {isLgScreen &&
                    <EquipmentOverlayBillboard partnerInfo={partnerInfo} setLeadCreationStatus={setLeadCreationStatus}></EquipmentOverlayBillboard>
                }

                <Container maxWidth={"md"} sx={{ display: { xs: 'block', lg: 'none' } }}>
                    <Box sx={{ textAlign: 'center', display: { xs: 'initial', lg: 'none' } }}>
                        <Typography sx={{
                            fontFamily: "'Montserrat', Helvetica, Arial, Verdana, sans-serif",
                            fontWeight: 600,
                            fontSize: { xs: '28px', sm: '36px', md: '36px', lg: '36px', xl: "42px" },
                            lineHeight: { xs: '30px', sm: '38px', md: '38px', lg: '38px', xl: "48px" },
                            padding: { xs: '12px 8px 24px 8px' },
                            color: '#545859'
                        }}>
                            An Exclusive offer for
                            <br></br>
                            Freedom Mortgage customers!
                        </Typography>
                    </Box>

                    <Box
                        component="img"
                        sx={{
                            maxWidth: "100%",
                            height: "auto",
                        }}
                        src={voucher750}
                    />

                    <Box sx={{ textAlign: 'center', padding: { xs: '1vh', md: '2vh', lg: '3vh', xl: '1vh 4vh 2vh 4vh', xxl: '4vh 4vh 3vh 4vh' }, display: { xs: 'initial', lg: 'none' } }}>
                        <Typography sx={{
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            fontFamily: "'Montserrat', Helvetica, Arial, Verdana, sans-serif",
                            fontWeight: 600,
                            fontSize: { xs: '18px', md: '28px' },
                            lineHeight: { xs: '20px', md: '30px' },
                            marginBottom: '10px',
                            padding: { xs: '12px 24px', sm: 'initial' },
                            color: '#545859'
                        }}>
                            ADT-Monitored system with doorbell camera*
                            <br></br>
                            PLUS a $750 equipment credit**
                            <br></br>
                            to further customize your home with Safe Haven.
                        </Typography>
                        <Typography sx={{
                            fontFamily: "'Montserrat', Helvetica, Arial, Verdana, sans-serif",
                            fontWeight: 600,
                            fontSize: { xs: '12px', md: '12px', lg: '16px', xl: "16px", xxl: '24px' },
                            lineHeight: { xs: '14px', md: '16px', lg: '20px', xl: "26px", xxl: '28px' },
                            marginBottom: '10px',
                            padding: { xs: '0px 24px', sm: 'initial' },
                            color: '#545859'
                        }}>
                            *Doorbell can be exchanged for another device of equal value ($250).
                        </Typography>
                        <Typography sx={{
                            fontFamily: "'Montserrat', Helvetica, Arial, Verdana, sans-serif",
                            fontWeight: 600,
                            fontSize: { xs: '12px', md: '12px', lg: '16px', xl: "16px", xxl: '24px' },
                            lineHeight: { xs: '16px', md: '16px', lg: '20px', xl: "26px", xxl: '28px' },
                            padding: { xs: '0px 24px', sm: 'initial' },
                            color: '#545859'
                        }}>
                            **With $99.00 Customer Installation Charge and purchase of alarm monitoring services. Early termination fees apply. See important Terms and Conditions to this offer below.
                        </Typography>
                    </Box>
                </Container>

                {!isLgScreen &&
                    <Container maxWidth="xl" className="freedom-mortgage-form-background" >
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                                try {
                                    // Add the hidden values to the submission data
                                    const submissionData = {
                                        ...values,
                                        partnerCompanyId: partnerInfo.PartnerId,
                                        partnerCampaignId: partnerInfo.CampaignId,
                                    };

                                    const postResponse: ApiResponseModel = await post("/Partner/CreateZohoLeadByPartnerId", submissionData);

                                    if (postResponse.code === 'OK') {
                                        const parsedContent = JSON.parse(postResponse.content);

                                        const newLeadId = parsedContent.details.output;
                                        const userMessage = JSON.parse(parsedContent.details.userMessage[0]);

                                        setLeadCreationStatus(true);

                                        console.log("New ID: " + newLeadId);

                                        navigate('/partners/confirmation', { state: { newLeadId, partnerInfo } });
                                    } else {
                                        console.log("Response code not OK: " + postResponse.code);
                                        setLeadCreationStatus(false);
                                    }

                                    console.log("Request sent successfully:", postResponse);
                                    resetForm();
                                } catch (error) {
                                    // Handle errors (e.g., show an error message)
                                    console.error("Error sending request:", error);
                                } finally {
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({ errors, touched, values, setFieldValue, isSubmitting }) => (
                                <Form>
                                    <Box className='fields-container freedom-mortgage'>
                                        <Field type="hidden" name="partnerCompanyId" />
                                        <Field type="hidden" name="PartnerCampaignId" />
                                        <Field
                                            as={TextField}
                                            sx={{ minHeight: '65px' }}
                                            className='half-width left-field freedom-mortgage-field'
                                            name="firstName"
                                            label="First Name *"
                                            value={values.firstName}
                                            onChange={(event: any) => {
                                                setFieldValue("firstName", event.target.value);
                                            }} error={touched.firstName && Boolean(errors.firstName)}
                                            helperText={touched.firstName && errors.firstName && (
                                                <div key={`error-firstName`}>{errors.firstName}</div>
                                            )} />

                                        <Field
                                            as={TextField}
                                            sx={{ minHeight: '65px' }}
                                            className='half-width right-field freedom-mortgage-field'
                                            name="lastName"
                                            label="Last Name *"
                                            value={values.lastName}
                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("lastName", event.target.value)}
                                            error={touched.lastName && Boolean(errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                        />

                                        <Field
                                            as={TextField}
                                            sx={{ minHeight: '65px' }}
                                            className='full-width freedom-mortgage-field'
                                            name="phone"
                                            label="Phone Number *"
                                            value={values.phone}
                                            onChange={(event: { target: { value: any; }; }) => {
                                                const inputValue = event.target.value.replace(/\D/g, '');

                                                let formattedValue = '';
                                                if (inputValue.length > 0) {
                                                    formattedValue = '(' + inputValue.substring(0, 3);
                                                    if (inputValue.length > 3) {
                                                        formattedValue += ') ' + inputValue.substring(3, 6);
                                                    }
                                                    if (inputValue.length > 6) {
                                                        formattedValue += '-' + inputValue.substring(6, 10);
                                                    }
                                                }

                                                setFieldValue('phone', formattedValue);
                                            }}
                                            onPaste={(event: { clipboardData: any; preventDefault: () => void; }) => {
                                                const clipboardData = event.clipboardData;
                                                const pastedData = clipboardData.getData('text');
                                                const cleanedData = pastedData.replace(/\D/g, '');
                                                const formattedValue = cleanedData.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                                setFieldValue('phone', formattedValue);
                                                event.preventDefault();
                                            }}
                                            error={touched.phone && Boolean(errors.phone)}
                                            helperText={touched.phone && errors.phone}
                                        />

                                        {!HIDE_ADRESS_FIELD.includes(partnerInfo.CampaignId) &&
                                            <Field
                                                as={TextField}
                                                sx={{ minHeight: '65px' }}
                                                className='full-width freedom-mortgage-field mini-form'
                                                name="address"
                                                label="Address *"
                                                value={values.address}
                                                onChange={(event: { target: { value: any; }; }) => setFieldValue("address", event.target.value)}
                                                error={touched.address && Boolean(errors.address)}
                                                helperText={touched.address && errors.address}
                                            />
                                        }

                                        <Field
                                            as={TextField}
                                            sx={{ minHeight: '65px' }}
                                            className='full-width freedom-mortgage-field'
                                            name="zip"
                                            label="Zip Code *"
                                            value={values.zip}
                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("zip", event.target.value)}
                                            error={touched.zip && Boolean(errors.zip)}
                                            helperText={touched.zip && errors.zip}
                                        />

                                        <Field
                                            as={TextField}
                                            sx={{ minHeight: '65px' }}
                                            className='full-width freedom-mortgage-field'
                                            name="email"
                                            label="Email *"
                                            value={values.email}
                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("email", event.target.value)}
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                        />

                                        <Field
                                            as={TextField}
                                            sx={{ minHeight: '120px' }}
                                            className='full-width freedom-mortgage-field mini-form'
                                            name="customerNotes"
                                            label="Comments"
                                            multiline
                                            rows={3}
                                            value={values.customerNotes}
                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("customerNotes", event.target.value)}
                                            error={touched.customerNotes && Boolean(errors.customerNotes)}
                                            helperText={touched.customerNotes && errors.customerNotes}
                                        />

                                        {SHOW_AGENT_NAME.includes(partnerInfo.CampaignId) &&
                                            <Field
                                                as={TextField}
                                                sx={{ minHeight: '65px' }}
                                                className='full-width freedom-mortgage-field mini-form'
                                                name="agentName"
                                                label="Agent Name *"
                                                value={values.agentName}
                                                onChange={(event: { target: { value: any; }; }) => setFieldValue("agentName", event.target.value)}
                                                error={touched.agentName && Boolean(errors.agentName)}
                                                helperText={touched.agentName && errors.agentName}
                                            />
                                        }

                                        {SHOW_CRM_ID.includes(partnerInfo.CampaignId) &&
                                            <Field
                                                as={TextField}
                                                sx={{ minHeight: '65px!important' }}
                                                className='full-width freedom-mortgage-field mini-form'
                                                name="crmId"
                                                label="CRM ID *"
                                                value={values.crmId}
                                                onChange={(event: { target: { value: any; }; }) => setFieldValue("crmId", event.target.value)}
                                                error={touched.crmId && Boolean(errors.crmId)}
                                                helperText={touched.crmId && errors.crmId}
                                            />
                                        }

                                        <Box sx={{ marginTop: '16px' }}>
                                            <Typography className='bottom-disclaimer-text'>
                                                By clicking the 'Get a Free Quote' button below, I agree that a Safe Haven team member may contact me via text messages or phone calls to the phone number provided by me using automated technology about ADT offers and consent is not required to make a purchase. Your information is collected and used in accordance with our privacy policy.
                                            </Typography>
                                        </Box>

                                        <Box sx={{ textAlign: 'center' }}>
                                            <LoadingButton
                                                variant='contained'
                                                className='freedom-mortgage-submit'
                                                type="submit"
                                                loading={isSubmitting}
                                                disabled={isSubmitting}
                                                sx={{
                                                    maxHeight: '56px',
                                                    minWidth: '100px',
                                                    fontSize: '16px',
                                                    textTransform: 'none',
                                                    fontWeight: '700',
                                                }}
                                            >
                                                {isSubmitting ? 'Submitting...' : 'Get a Free Quote'}
                                            </LoadingButton>
                                        </Box>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Container>
                }

                <Grid
                    container
                    rowSpacing={{ xs: 5, sm: 0, lg: 5 }}
                    columnSpacing={{ lg: 5 }}
                    maxWidth={'xl'} sx={{ margin: '32px auto!important', textAlign: 'center' }}
                >
                    <Grid item xs={12}>
                        <Typography sx={{
                            color: "#545859",
                            font: "normal 600 34px / 44px 'Montserrat', Helvetica, Arial, Verdana, sans-serif",
                            textAlign: 'center',
                            marginBottom: '32px',
                        }}>
                            You’re only three steps away from the best security in the industry.
                        </Typography>

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography className='blue-text-column-header'>1. Talk to an expert</Typography>
                        <Typography className='blue-text-column-body' sx={{ padding: '8px', margin: 'auto' }}>Talk to a Safe Haven expert about your needs and get a free quote.</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography className='blue-text-column-header'>2. Customize your system</Typography>
                        <Typography className='blue-text-column-body' sx={{ padding: '8px', margin: 'auto' }}>One of our experts will help you customize your system specifically to your home.</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography className='blue-text-column-header'>3. Get installed</Typography>
                        <Typography className='blue-text-column-body' sx={{ padding: '8px', margin: 'auto' }}>Schedule your professionally installed system from Safe Haven.</Typography>
                    </Grid>
                </Grid>

                <Container maxWidth={false} sx={{ borderTop: '1px solid #ebebeb', borderBottom: '1px solid #ebebeb', paddingTop: '36px', maxWidth: '1300px' }}>
                    <Grid container rowSpacing={5} columnSpacing={5} className='margin-bottom-35'>
                        <Grid item xs={0} sm={6} sx={{ display: { xs: 'none', sm: 'initial' } }}>
                            <Typography className='white-glove-header'>
                                White-Glove Installation is included!
                            </Typography>
                            <Typography className='white-glove-body'>
                                SafeHaven Installation Techs are the leaders in ADT Smart Security installations. We offer same-day or next-day installations depending on your area.
                            </Typography>
                            <Box sx={{ marginTop: '36px' }}>
                                <ul className="blue-checkmark white-glove-body">
                                    <li>Same-Day or Next Day Installations</li>
                                    <li>ADT Theft Protection Guarantee+</li>
                                    <li>Service &amp; Repairs Included</li>
                                    <li>Award-Winning Technology++</li>
                                </ul>
                            </Box>
                            <Box sx={{ textAlign: 'center', marginTop: '36px' }}>
                                <Button sx={{ transition: 'none' }} className='freedom-mortgage-submit' type="submit" variant="contained" onClick={handleScrollToBottomForm}>GET A FREE QUOTE</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box>
                                <Box
                                    component="img"
                                    sx={{
                                        maxWidth: "100%",
                                        height: "auto",

                                    }}
                                    src={installTech}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={0} sx={{ display: { xs: 'initial', sm: 'none' } }}>
                            <Typography className='white-glove-header'>
                                White-Glove Installation is included!
                            </Typography>
                            <Typography className='white-glove-body'>
                                SafeHaven Installation Techs are the leaders in ADT Smart Security installations. We offer same-day or next-day installations depending on your area.
                            </Typography>
                            <Box sx={{ marginTop: '36px' }}>
                                <ul className="blue-checkmark white-glove-body">
                                    <li>Same-Day or Next Day Installations</li>
                                    <li>ADT Theft Protection Guarantee+</li>
                                    <li>Service &amp; Repairs Included</li>
                                    <li>Award-Winning Technology++</li>
                                </ul>
                            </Box>
                            <Box sx={{ textAlign: 'center', marginTop: '36px' }}>
                                <Button sx={{ transition: 'none' }} className='freedom-mortgage-submit' type="submit" variant="contained" onClick={handleScrollToBottomForm}>GET A FREE QUOTE</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box>
                                <Box
                                    component="img"
                                    sx={{
                                        maxWidth: "100%",
                                        height: "auto",
                                    }}
                                    src={familyPic}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className='white-glove-header'>
                                Why ADT.
                            </Typography>
                            <Typography className='white-glove-body'>
                                When helping to protect your family or home, you shouldn’t cut corners. ADT has proven that they are the leader in home security and automation monitoring.
                            </Typography>
                            <Box>
                                <ul className="blue-checkmark white-glove-body">
                                    <li>145+ Years of Experience</li>
                                    <li>24/7 Professional Monitoring</li>
                                    <li>A+ BBB Rating</li>
                                    <li>ADT 6-Month Money Back Guarantee+</li>
                                </ul>
                            </Box>
                            <Box sx={{ textAlign: 'center', marginTop: '36px' }}>
                                <Button sx={{ transition: 'none' }} className='freedom-mortgage-submit' type="submit" variant="contained" onClick={handleScrollToBottomForm}>GET A FREE QUOTE</Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className='margin-bottom-35 '>
                        <Typography className='margin-bottom-10 white-glove-header text-center'>Reviews</Typography>
                        <Typography className='text-center blue-text-column-header'>
                            What Our Customers Are Saying
                        </Typography>
                        <Box className='google-reviews'>
                            {/* <GoogleReviews></GoogleReviews> */}
                        </Box>
                    </Box>
                </Container>

                <Container maxWidth={"xl"} sx={{ marginBottom: { xs: '32px', lg: '48px' } }}>
                    <Typography className='white-glove-header text-center'>HOME SECURITY PLANS AND PACKAGES</Typography>
                    <Typography className='text-center blue-text-column-header-large margin-bottom-35'>We’ve got a package to fit your needs.</Typography>
                    <Typography className='white-glove-body text-center text-size-24 margin-bottom-35' >
                        Consider these a great place to start. Our Smart Home Concierge Team will walk you through customizing a package just for you.
                        EVERY package includes:
                    </Typography>
                    <Box sx={{ display: 'flex', }}>
                        <ul className="green-checkmark package-list white-glove-body">
                            <li>Upgraded 7″ Touchscreen Video Panel</li>
                            <li>Interior Motion Sensor</li>
                            <li>Three Door/Window Sensors</li>
                        </ul>
                    </Box>

                    <Grid container>
                        <Grid item xs={12} md={4} >
                            <Stack className='package-stack' sx={{ padding: { xs: '16px 24px', lg: '16px 42px' } }}>
                                <Typography className='package-tile-header text-center '>Remote</Typography>
                                <Box
                                    component="img"
                                    sx={{
                                        maxWidth: "100%",
                                        height: "auto",

                                    }}
                                    src={remote}
                                />
                                <Box className='white-glove-body'>
                                    <ul>
                                        <li>Professional Installation</li>
                                        <li>24/7 ADT Monitoring</li>
                                        <li>Panel Camera</li>
                                        <li>6-Month ADT Money-Back Guarantee+</li>
                                    </ul>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Button sx={{ transition: 'none' }} className='package-tile-btn' type="submit" variant="contained" onClick={handleScrollToBottomForm}>GET A FREE QUOTE</Button>
                                </Box>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4} >
                            <Stack className='package-stack' sx={{ padding: { xs: '16px 24px', lg: '16px 42px' } }}>
                                <Typography className='package-tile-header text-center '>Control</Typography>
                                <Box
                                    component="img"
                                    sx={{
                                        maxWidth: "100%",
                                        height: "auto",

                                    }}
                                    src={control}
                                />
                                <Box className='white-glove-body'>
                                    <ul>
                                        <li>Professional Installation</li>
                                        <li>24/7 ADT Monitoring</li>
                                        <li>Panel Camera</li>
                                        <li>Smart Home Control</li>
                                        <li>Alarm.com app</li>
                                        <li>6-Month ADT Money-Back Guarantee+</li>
                                    </ul>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Button sx={{ transition: 'none' }} className='package-tile-btn' type="submit" variant="contained" onClick={handleScrollToBottomForm}>GET A FREE QUOTE</Button>
                                </Box>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4} >
                            <Stack className='package-stack' sx={{ justifyContent: 'space-around', padding: { xs: '16px 24px', lg: '16px 42px' } }}>
                                <Typography className='package-tile-header text-center '>Video & Home Automation</Typography>
                                <Box
                                    component="img"
                                    sx={{
                                        maxWidth: "100%",
                                        height: "auto",

                                    }}
                                    src={automation}
                                />
                                <Box className='white-glove-body'>
                                    <ul>
                                        <li>Professional Installation</li>
                                        <li>24/7 ADT Monitoring</li>
                                        <li>Panel Camera</li>
                                        <li>Smart Home Control</li>
                                        <li>Alarm.com app</li>
                                        <li>Real-Time Video</li>
                                        <li>6-Month ADT Money-Back Guarantee+</li>
                                    </ul>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Button sx={{ transition: 'none' }} className='package-tile-btn' type="submit" variant="contained" onClick={handleScrollToBottomForm}>GET A FREE QUOTE</Button>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>

                <Box className='freedom-mortgage-bottom-form' sx={{}}>
                    <Box className=''>
                        <Grid container columnSpacing={{ xs: 1, md: 3, lg: 5 }} sx={{ justifyContent: 'center', flexDirection: { xs: "column-reverse", md: 'initial' } }}>
                            <Grid item xs={12} md={6} lg={6} xl={4}>
                                <Container maxWidth="xl" className="freedom-mortgage-form-background-bottom" ref={bottomFormRef} sx={{ maxWidth: '600px' }}>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                                            try {
                                                // Add the hidden values to the submission data
                                                const submissionData = {
                                                    ...values,
                                                    partnerCompanyId: '1265',
                                                    partnerCampaignId: '2759',

                                                };

                                                const postResponse = await post("/Partner/CreateZohoLeadByPartnerId", submissionData);

                                                // Handle successful submission (e.g., show a success message)
                                                console.log("Request sent successfully:", postResponse);
                                                resetForm();
                                            } catch (error) {
                                                // Handle errors (e.g., show an error message)
                                                console.error("Error sending request:", error);
                                            } finally {
                                                setSubmitting(false);
                                            }
                                        }}
                                    >
                                        {({ errors, touched, isSubmitting, values, setFieldValue }) => ( // Access Formik state here
                                            <Form>
                                                <Box className='fields-container freedom-mortgage' sx={{ margin: '0!important', paddingLeft: '16px', paddingRight: '16px', paddingTop: '16px' }}>
                                                    <Field
                                                        as={TextField}
                                                        sx={{ minHeight: '65px!important' }}
                                                        className='half-width left-field freedom-mortgage-field bottom-form'
                                                        name="firstName"
                                                        label="First Name *"
                                                        error={touched.firstName && Boolean(errors.firstName)} // Check if touched and error exists
                                                        helperText={touched.firstName && errors.firstName} // Show error message if touched
                                                    />

                                                    <Field
                                                        as={TextField}
                                                        sx={{ minHeight: '65px!important' }}
                                                        className='half-width right-field freedom-mortgage-field bottom-form'
                                                        name="lastName"
                                                        label="Last Name *"
                                                        error={touched.lastName && Boolean(errors.lastName)}
                                                        helperText={touched.lastName && errors.lastName}
                                                    />

                                                    <Field
                                                        as={TextField}
                                                        sx={{ minHeight: '65px!important' }}
                                                        className='full-width freedom-mortgage-field bottom-form'
                                                        name="phone"
                                                        label="Phone Number *"
                                                        maxLength={10}
                                                        onChange={(event: { target: { value: any; }; }) => {
                                                            const inputValue = event.target.value.replace(/\D/g, '');

                                                            let formattedValue = '';
                                                            if (inputValue.length > 0) {
                                                                formattedValue = '(' + inputValue.substring(0, 3);
                                                                if (inputValue.length > 3) {
                                                                    formattedValue += ') ' + inputValue.substring(3, 6);
                                                                }
                                                                if (inputValue.length > 6) {
                                                                    formattedValue += '-' + inputValue.substring(6, 10);
                                                                }
                                                            }

                                                            setFieldValue('phone', formattedValue);
                                                        }}
                                                        onPaste={(event: { clipboardData: any; preventDefault: () => void; }) => {
                                                            const clipboardData = event.clipboardData;
                                                            const pastedData = clipboardData.getData('text');
                                                            const cleanedData = pastedData.replace(/\D/g, '');
                                                            const formattedValue = cleanedData.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                                            setFieldValue('phone', formattedValue);
                                                            event.preventDefault();
                                                        }}
                                                        error={touched.phone && Boolean(errors.phone)}
                                                        helperText={touched.phone && errors.phone}
                                                    />

                                                    {!HIDE_ADRESS_FIELD.includes(partnerInfo.CampaignId) &&
                                                        <Field
                                                            as={TextField}
                                                            sx={{ minHeight: '65px' }}
                                                            className='full-width freedom-mortgage-field mini-form'
                                                            name="address"
                                                            label="Address *"
                                                            value={values.address}
                                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("address", event.target.value)}
                                                            error={touched.address && Boolean(errors.address)}
                                                            helperText={touched.address && errors.address}
                                                        />
                                                    }

                                                    <Field
                                                        as={TextField}
                                                        sx={{ minHeight: '65px!important' }}
                                                        className='full-width freedom-mortgage-field bottom-form'
                                                        name="zip"
                                                        label="Zip Code *"
                                                        error={touched.zip && Boolean(errors.zip)}
                                                        helperText={touched.zip && errors.zip}
                                                    />

                                                    <Field
                                                        as={TextField}
                                                        sx={{ minHeight: '65px!important' }}
                                                        className='full-width freedom-mortgage-field bottom-form'
                                                        name="email"
                                                        label="Email *"
                                                        error={touched.email && Boolean(errors.email)}
                                                        helperText={touched.email && errors.email}
                                                    />

                                                    <Field
                                                        as={TextField}
                                                        sx={{ minHeight: '120px!important' }}
                                                        className='full-width freedom-mortgage-field bottom-form'
                                                        name="customerNotes"
                                                        label="Comments"
                                                        multiline
                                                        rows={3}
                                                        value={values.customerNotes}
                                                        onChange={(event: { target: { value: any; }; }) => setFieldValue("customerNotes", event.target.value)}
                                                        error={touched.customerNotes && Boolean(errors.customerNotes)}
                                                        helperText={touched.customerNotes && errors.customerNotes}
                                                    />

                                                    {SHOW_AGENT_NAME.includes(partnerInfo.CampaignId) &&
                                                        <Field
                                                            as={TextField}
                                                            sx={{ minHeight: '65px!important' }}
                                                            className='full-width freedom-mortgage-field mini-form'
                                                            name="agentName"
                                                            label="Agent Name *"
                                                            value={values.agentName}
                                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("agentName", event.target.value)}
                                                            error={touched.agentName && Boolean(errors.agentName)}
                                                            helperText={touched.agentName && errors.agentName}
                                                        />
                                                    }

                                                    {SHOW_CRM_ID.includes(partnerInfo.CampaignId) &&
                                                        <Field
                                                            as={TextField}
                                                            sx={{ minHeight: '65px!important' }}
                                                            className='full-width freedom-mortgage-field mini-form'
                                                            name="crmId"
                                                            label="CRM ID *"
                                                            value={values.crmId}
                                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("crmId", event.target.value)}
                                                            error={touched.crmId && Boolean(errors.crmId)}
                                                            helperText={touched.crmId && errors.crmId}
                                                        />
                                                    }

                                                    <Box sx={{ display: 'flex', marginTop: '10px' }}>
                                                        <Typography className='bottom-form-disclaimer-text' sx={{ paddingRight: '5px' }}>*</Typography>
                                                        <Typography className='bottom-form-disclaimer-text'>
                                                            By clicking the 'Get a Free Quote' button below, I agree that a Safe Haven team member may contact me via text messages or phone calls to the phone number provided by me using automated technology about ADT offers and consent is not required to make a purchase. Your information is collected and used in accordance with our privacy policy.
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{ textAlign: 'center', marginBottom: '0!important' }}>
                                                        <LoadingButton
                                                            variant='contained'
                                                            className=''
                                                            type="submit"
                                                            loading={isSubmitting}
                                                            disabled={isSubmitting}
                                                            sx={{
                                                                marginTop: '10px',
                                                                maxHeight: '56px',
                                                                minWidth: '100px',
                                                                fontSize: '16px',
                                                                textTransform: 'none',
                                                                fontWeight: '700',
                                                                font: 'normal bold 14px / 16px "Roboto", Helvetica, Arial, Verdana, sans-serif',
                                                                borderRadius: '1px',
                                                                border: '0 solid #0061aa',
                                                                padding: '12px 18px',
                                                                transition: 'none',
                                                                '&:hover': {
                                                                    color: "#fff",
                                                                    background: 'linear-gradient(135deg, #0061aa 30%, #eaeef5 100%) !important',
                                                                    borderColor: '#0061aa !important'
                                                                }
                                                            }}
                                                        >
                                                            {isSubmitting ? 'Submitting...' : 'Submit'}
                                                        </LoadingButton>
                                                    </Box>
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>
                                </Container>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Stack sx={{}}>
                                    <Box className='' sx={{ justifyContent: { xs: 'center', md: 'left' }, display: 'flex', flexWrap: { xs: 'wrap!important', xl: 'nowrap!important' }, margin: { xs: 'auto', md: '24px 0 ' }, marginBottom: '24px', width: { xs: '100%', md: 'auto' } }}>
                                        {(HIDE_PARTNER_LOGO.includes(pathname) || pathname === '/partners/selectquotedigitalreferral') ?
                                            null :
                                            <Box sx={{
                                                backgroundColor: (pathname.includes('freedom') || pathname.includes('myprotectionhero')) ? 'rgba(255, 255, 255, .5)' : '',
                                                boxShadow: (pathname.includes('freedom') || pathname.includes('myprotectionhero')) ? '0px 0px 10px 10px rgba(255, 255, 255, .5)' : '',
                                                margin: (pathname.includes('freedom') || pathname.includes('myprotectionhero') || pathname.includes('jocelyn-rivard') || pathname.includes('bethanywhitegroup')) ? { xs: '16px 16px 8px 0', md: '0 16px 8px 0' } : '',
                                            }}>
                                                <PartnerLogo partnerInfo={partnerInfo}></PartnerLogo>
                                            </Box>
                                        }

                                        <Typography className='bottom-form-header-freedom' sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', lg: 'left' }, marginTop: { xs: '24px', lg: 'initial' }, textAlign: { xs: 'center', md: 'left' } }}>
                                            An Exclusive offer for  <br></br>
                                            Freedom Mortgage customers!
                                        </Typography>
                                    </Box>
                                    <Typography className='limited-offer-text-freedom' sx={{ color: 'yellow', textAlign: { xs: 'center', md: 'left' }, marginBottom: '24px' }}>LIMITED TIME OFFER</Typography>
                                    <Typography sx={{
                                        font: "normal 500 24px / 34px 'Montserrat', Helvetica, Arial, Verdana, sans-serif",
                                        color: '#ffffff',
                                        marginBottom: '24px',
                                        textAlign: { xs: 'center', md: 'left' }
                                    }}>
                                        Call today to get your base system and $750 in custom equipment!
                                    </Typography>
                                    <Typography sx={{
                                        font: "normal 500 24px / 34px 'Montserrat', Helvetica, Arial, Verdana, sans-serif",
                                        color: '#ffffff',
                                        marginBottom: '10px',
                                        textAlign: { xs: 'center', md: 'left' }
                                    }}>
                                        Call us at: <Link className='yellow-text' sx={{ fontWeight: 500 }} href="tel:8778502088" underline="none">877-850-2088</Link>
                                    </Typography>
                                    <Typography sx={{
                                        font: "normal 500 24px / 34px 'Montserrat', Helvetica, Arial, Verdana, sans-serif",
                                        color: '#ffffff',
                                        marginBottom: '35px',
                                        textAlign: { xs: 'center', md: 'left' }
                                    }}>
                                        Or please fill out the form and a Safe Haven concierge team member will reach out to you to discuss your options.
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Box sx={{ marginBottom: '16px' }}>
                            <Typography className='bottom-disclaimer-freedom-mortgage' sx={{ marginTop: '35px', marginBottom: '35px' }}>
                                Safe Haven Security LLC is not affiliated with Freedom Mortgage Corporation, its affiliates, or subsidiaries. Freedom Mortgage Corporation does not guarantee nor is it responsible for the entities, agents, contractors, services, products or plans described herein. Freedom Mortgage Corporation is compensated for its endorsement of these home security products (“Products”). Purchase of the Products is optional and is not required by the terms of your mortgage loan. If you purchase the Products, the payment obligations for the Products will not be secured by your home. You are responsible for the evaluation, selection and suitability of the Products.
                            </Typography>
                            <Typography className='bottom-disclaimer-freedom-mortgage'>
                                Dealer License Numbers by State: AL: 16/17-1493, City of Birmingham: 000153, Gardendale: 2015-42, Jefferson County: 16008032 AZ: 255474-CR67, BTR 18404-0 CA: ACO5325/ACQ4665, Sacramento: ACO5325/BOT1019542 CO: City of Denver: 1037585, City of Aurora: 176007, Colorado Springs: 722500 FL: EF20000905, City of Jacksonville: Company #253, Palm Beach County: EF20000905, Alachua County: 12937 GA: LVU406182 IA: AC-191 IL: 127.001386/124.001721, Village of Bellwood: #2015109 IN: Indianapolis: LAC-000104 LA: E11859/F1672 MD: 107-1696, Prince Georges Co: LIC-820, Baltimore Co: 1696, Howard County: #528, Montgomery Co: 01379 MI: 3601205667 MO: 00504575, Kansas City: 0103287936/203215, Independence: 27550, North Kansas City: 004300, Raytown: 008920 St. Louis County: 79939, St. Joseph: BL16-18878 MN: 185972/TS000034 MS: 15019531, NE: 101147018, City of Omaha: FAR #766 NJ: 34BX00006600 NY: 12000077734 NC: 2228-CSA NV: NV20141168572, Las Vegas: 107696, Clark County: 2001215-023-140 OH: 1804841 OK: A&L #1802 OR: CCB194899 PA: PA-040539 SC: BAC13574 TN: 1720 TX: B14839, ACR-1711098 VA: 11-6402, Loudoun County 659, Hampton #123620 WA: SAFEHHS898B5, City of Auburn: BUS-29209, Bellevue 97322, Federal Way 20-15-105341-00-BL, Tacoma 500090577
                            </Typography>
                            <Box sx={{ margin: 'auto', textAlign: 'center' }}>
                                <Link sx={{
                                    color: '#fff',
                                    font: 'normal 15px / 27px "Montserrat", Helvetica, Arial, Verdana, sans-serif',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => {
                                        {
                                            handleNavigate('/terms-and-conditions');
                                        }
                                    }}
                                >
                                    +Terms and Conditions
                                </Link>
                                <br></br>
                                <a href="https://qolsys.com/category/awards/"
                                    target="_blank"
                                    rel="noopener"
                                    className='qolsys-link'
                                >++QOLSYS Awards
                                </a>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>

    );
}
