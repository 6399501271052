import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import './index.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Button, Grid, Link, TextField } from '@mui/material';
import { Container, Stack } from '@mui/system';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup'; // For form validation
import useFetch from 'use-http';
import equipmentBg from "../../../../../Assets/Partner/FreedomMortgage/New-equipment-layout_750-voucher-scaled-e1631196825855.jpg";
import equipmentBg2 from "../../../../../Assets/Templates/New-equipment-layout_no-voucher-scaled.jpg";
import { HIDE_ADRESS_FIELD, SHOW_AGENT_NAME, SHOW_CRM_ID } from '../../Constants';

interface propsWrapper {
    partnerInfo: PartnerInfo;
    setLeadCreationStatus: Dispatch<SetStateAction<boolean | null>>;
}

export default function EquipmentOverlayBillboard(props: propsWrapper) {
    const { partnerInfo, setLeadCreationStatus } = props;
    const navigate = useNavigate();

    useEffect(() => {
        console.log(partnerInfo);
    }, []);

    // Formik Initial Values and Validation Schema
    const initialValues = {
        campaignId: partnerInfo.CampaignId,
        firstName: '',
        lastName: '',
        address: '',
        zip: '',
        phone: '',
        email: '',
        comments: '',
        agentName: '',
        crmId: '',
    };

    const validationSchema = Yup.object({
        campaignId: Yup.string(),
        firstName: Yup.string().required('Required')
            .min(2, 'First name must be at least 2 characters')
            .max(50, 'First name must be at most 50 characters')
            .matches(/^[a-zA-Z '-]+$/, 'First name can only contain letters, hyphens, apostrophes, and spaces'),
        lastName: Yup.string().required('Required'),
        zip: Yup.string().matches(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code format').required('Required'),
        phone: Yup.string().required('Required')
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        address: Yup.string().required('Required'),
        comments: Yup.string(),
        agentName: Yup.string().when('campaignId', {
            is: "3733",
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
    });

    let apiKey = process.env.REACT_APP_API__ACCESS_KEY
        ? process.env.REACT_APP_API__ACCESS_KEY
        : '';
    const options = {
        headers: {
            'Content-Type': 'application/json',
            Connection: 'keep-alive',
            'Accept-Encoding': 'gzip, deflate, br',
            'X-API-Key': apiKey,
        },
        cacheLife: 1000,
    };

    const { post, response, loading } = useFetch(
        process.env.REACT_APP_API,
        options
    );

    return (
        <>
            <Container maxWidth={false} sx={{ position: 'relative', textAlign: 'center', maxWidth: '100%', padding: '0!important', overflow: 'hidden', minHeight: '1279px', maxHeight: '1279px' }}>
                {/* BG Image */}
                <Box
                    sx={{ transform: { xs: 'translate(0%, -10%)', lg: 'translate(-10%, -10%)', xl: 'translate(0%, -10%)' } }}
                    component="img"
                    className='equipment-image'
                    src={equipmentBg2}
                />

                {/* Top Text */}
                <Box sx={{ position: 'absolute', top: { lg: '4vh', xl: '5vh' }, left: '50%', transform: { xs: 'translate(-50%, 0%)' }, width: '75vw', }}>
                    <Typography className='white-title' sx={{
                        color: "#fff",
                        fontFamily: "'Montserrat', Helvetica, Arial, Verdana, sans-serif",
                        fontWeight: 600,
                        fontSize: { xs: '16px', md: '24px', lg: '36px', xl: "42px" },
                        lineHeight: '46px',
                        padding: { xs: '3vh', md: '3vh', lg: '2vh', xl: '3vh' },
                        zIndex: 2
                    }}>
                        An Exclusive offer for Freedom Mortgage customers!
                    </Typography>
                </Box>

                <Box sx={{
                    position: 'absolute',
                    top: '47vh',
                    right: { xs: 'unset', xl: 'calc(24px + 1vw)' }, // 24px for container padding
                    left: { xs: '50%', xl: 'unset' },
                    transform: { xs: 'translate(-50%, -50%)', xl: 'translate(0%, -50%)' },
                    minWidth: '500px',
                    bgcolor: { xs: 'rgba(33, 108, 206, 0.83) !important', xl: 'rgba(33, 108, 206, 0.50) !important' },
                    borderRadius: '10px',
                    padding: '0',
                    width: { xs: '650px', xl: '500px' },
                    maxWidth: { xs: '60vw', xl: '25vw' },
                    minHeight: '45vh',
                }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            try {
                                //Add the hidden values to the submission data
                                const submissionData = {
                                    ...values,
                                    partnerCompanyId: partnerInfo.PartnerId,
                                    partnerCampaignId: partnerInfo.CampaignId,
                                };

                                const postResponse: ApiResponseModel = await post("/Partner/CreateZohoLeadByPartnerId", submissionData);

                                if (postResponse.code === 'OK') {
                                    const parsedContent = JSON.parse(postResponse.content);

                                    const newLeadId = parsedContent.details.output;
                                    const userMessage = JSON.parse(parsedContent.details.userMessage[0]);

                                    setLeadCreationStatus(true);

                                    console.log("New ID: " + newLeadId);

                                    navigate('/partners/confirmation', { state: { newLeadId, partnerInfo } });
                                } else {
                                    console.log("Response code not OK: " + postResponse.code);
                                    setLeadCreationStatus(false);
                                }

                                console.log("Request sent successfully:", postResponse);
                                resetForm();
                            } catch (error) {
                                // Handle errors (e.g., show an error message)
                                console.error("Error sending request:", error);
                            } finally {
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({ errors, touched, values, setFieldValue, isSubmitting }) => ( // Access Formik state here
                            <Form>
                                <Box className='fields-container freedom-mortgage' sx={{ margin: '24px 12px!important' }}>
                                    <Field type="hidden" name="partnerCompanyId" />
                                    <Field type="hidden" name="PartnerCampaignId" />

                                    <Field
                                        as={TextField}
                                        sx={{ minHeight: '65px' }}
                                        className='half-width left-field freedom-mortgage-field mini-form'
                                        name="firstName"
                                        label="First Name *"
                                        value={values.firstName}
                                        onChange={(event: any) => {
                                            setFieldValue("firstName", event.target.value);
                                        }} error={touched.firstName && Boolean(errors.firstName)} // Check if touched and error exists
                                        helperText={touched.firstName && errors.firstName && (
                                            <div key={`error-firstName`}>{errors.firstName}</div> // Unique key
                                        )}
                                    />

                                    <Field
                                        as={TextField}
                                        sx={{ minHeight: '65px' }}
                                        className='half-width right-field freedom-mortgage-field mini-form'
                                        name="lastName"
                                        label="Last Name *"
                                        value={values.lastName}
                                        onChange={(event: { target: { value: any; }; }) => setFieldValue("lastName", event.target.value)}
                                        error={touched.lastName && Boolean(errors.lastName)}
                                        helperText={touched.lastName && errors.lastName}
                                    />

                                    <Field
                                        as={TextField}
                                        sx={{ minHeight: '65px' }}
                                        className='full-width freedom-mortgage-field mini-form'
                                        name="phone"
                                        label="Phone Number *"
                                        value={values.phone}
                                        onChange={(event: { target: { value: any; }; }) => {
                                            const inputValue = event.target.value.replace(/\D/g, '');

                                            let formattedValue = '';
                                            if (inputValue.length > 0) {
                                                formattedValue = '(' + inputValue.substring(0, 3);
                                                if (inputValue.length > 3) {
                                                    formattedValue += ') ' + inputValue.substring(3, 6);
                                                }
                                                if (inputValue.length > 6) {
                                                    formattedValue += '-' + inputValue.substring(6, 10);
                                                }
                                            }

                                            setFieldValue('phone', formattedValue);
                                        }}
                                        onPaste={(event: { clipboardData: any; preventDefault: () => void; }) => {
                                            const clipboardData = event.clipboardData;
                                            const pastedData = clipboardData.getData('text');
                                            const cleanedData = pastedData.replace(/\D/g, '');
                                            const formattedValue = cleanedData.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                            setFieldValue('phone', formattedValue);
                                            event.preventDefault();
                                        }}
                                        error={touched.phone && Boolean(errors.phone)}
                                        helperText={touched.phone && errors.phone}
                                    />

                                    {!HIDE_ADRESS_FIELD.includes(partnerInfo.CampaignId) &&
                                        <Field
                                            as={TextField}
                                            sx={{ minHeight: '65px' }}
                                            className='full-width freedom-mortgage-field mini-form'
                                            name="address"
                                            label="Address *"
                                            value={values.address}
                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("address", event.target.value)}
                                            error={touched.address && Boolean(errors.address)}
                                            helperText={touched.address && errors.address}
                                        />
                                    }

                                    <Field
                                        as={TextField}
                                        sx={{ minHeight: '65px' }}
                                        className='full-width freedom-mortgage-field mini-form'
                                        name="zip"
                                        label="Zip Code *"
                                        value={values.zip}
                                        onChange={(event: { target: { value: any; }; }) => {
                                            let inputValue = event.target.value;

                                            inputValue = inputValue.slice(0, 5);

                                            inputValue = inputValue.replace(/\D/g, '');

                                            setFieldValue('zip', inputValue);
                                        }}
                                        onPaste={(event: { clipboardData: any; preventDefault: () => void; }) => {
                                            const clipboardData = event.clipboardData;
                                            const pastedData = clipboardData.getData('text');
                                            const cleanedData = pastedData.replace(/\D/g, '');
                                            setFieldValue('zip', cleanedData);
                                            event.preventDefault();
                                        }}
                                        error={touched.zip && Boolean(errors.zip)}
                                        helperText={touched.zip && errors.zip}
                                    />

                                    <Field
                                        as={TextField}
                                        sx={{ minHeight: '65px' }}
                                        className='full-width freedom-mortgage-field mini-form'
                                        name="email"
                                        label="Email *"
                                        value={values.email}
                                        onChange={(event: { target: { value: any; }; }) => setFieldValue("email", event.target.value)}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                    />

                                    <Field
                                        as={TextField}
                                        sx={{
                                            minHeight: '120px',
                                            '& textarea': {
                                                zIndex: 1
                                            },
                                        }}
                                        className='full-width freedom-mortgage-field mini-form'
                                        name="customerNotes"
                                        label="Place any additional info here."
                                        multiline
                                        rows={3}
                                        value={values.comments}
                                        onChange={(event: { target: { value: any; }; }) => setFieldValue("customerNotes", event.target.value)}
                                        error={touched.comments && Boolean(errors.comments)}
                                        helperText={touched.comments && errors.comments}
                                    />

                                    {SHOW_AGENT_NAME.includes(partnerInfo.CampaignId) &&
                                        <Field
                                            as={TextField}
                                            sx={{ minHeight: '65px' }}
                                            className='full-width freedom-mortgage-field mini-form'
                                            name="agentName"
                                            label="Agent Name *"
                                            value={values.agentName}
                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("agentName", event.target.value)}
                                            error={touched.agentName && Boolean(errors.agentName)}
                                            helperText={touched.agentName && errors.agentName}
                                        />
                                    }

                                    {SHOW_CRM_ID.includes(partnerInfo.CampaignId) &&
                                        <Field
                                            as={TextField}
                                            sx={{ minHeight: '65px!important' }}
                                            className='full-width freedom-mortgage-field mini-form'
                                            name="crmId"
                                            label="CRM ID *"
                                            value={values.crmId}
                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("crmId", event.target.value)}
                                            error={touched.crmId && Boolean(errors.crmId)}
                                            helperText={touched.crmId && errors.crmId}
                                        />
                                    }

                                    <Box sx={{ marginBottom: '0!important', marginTop: '10px' }}>
                                        <Typography className='bottom-disclaimer-text' sx={{ fontSize: "10px!important", lineHeight: "13px!important", color: 'white', fontWeight: 'normal!important', textAlign: 'left', margin: '0 12px' }}>
                                            By clicking the 'Submit' button below, I agree that a Safe Haven team member may contact me via text messages or phone calls to the phone number provided by me using automated technology about ADT offers and consent is not required to make a purchase. Your information is collected and used in accordance with our privacy policy.
                                        </Typography>
                                    </Box>

                                    <Box sx={{ textAlign: 'center', marginBottom: '0!important' }}>
                                        <LoadingButton
                                            variant='contained'
                                            className=''
                                            type="submit"
                                            loading={isSubmitting}
                                            disabled={isSubmitting}
                                            sx={{
                                                maxHeight: '56px',
                                                minWidth: '100px',
                                                fontSize: '16px',
                                                textTransform: 'none',
                                                fontWeight: '700',
                                                font: 'normal bold 14px / 16px "Roboto", Helvetica, Arial, Verdana, sans-serif',
                                                borderRadius: '1px',
                                                border: '0 solid #0061aa',
                                                padding: '12px 18px',
                                                transition: 'none',
                                                '&:hover': {
                                                    color: "#fff",
                                                    background: 'linear-gradient(135deg, #0061aa 30%, #eaeef5 100%) !important',
                                                    borderColor: '#0061aa !important'
                                                }
                                            }}
                                        >
                                            {isSubmitting ? 'Submitting...' : 'Submit'}
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>

                {/* Bottom Text */}
                <Box sx={{ position: 'absolute', bottom: { xs: '1vh', sm: '1vh', md: '2vh', lg: '12vh', xl: '10vh' }, left: '50%', transform: 'translate(-50%, 0%)', width: { xs: '75vw', lg: '85vw', xl: '75vw' }, }}>
                    <Typography sx={{
                        color: "#fff",
                        fontFamily: "'Montserrat', Helvetica, Arial, Verdana, sans-serif",
                        fontWeight: 600,
                        fontSize: { xs: '16px', md: '18px', lg: '28px', xl: "30px" },
                        lineHeight: { xs: '18px', md: '20px', lg: '30px', xl: "36px" },
                        marginBottom: '10px',
                    }}>
                        ADT-Monitored system with doorbell camera* PLUS a $750 equipment credit** to further customize your home with Safe Haven.
                    </Typography>
                    <Typography sx={{
                        color: "#fff",
                        fontFamily: "'Montserrat', Helvetica, Arial, Verdana, sans-serif",
                        fontWeight: 600,
                        fontSize: { xs: '12px', md: '12px', lg: '16px', xl: "16px" },
                        lineHeight: { xs: '14px', md: '16px', lg: '20px', xl: "20px" },
                        marginBottom: '10px',
                    }}>
                        *Doorbell can be exchanged for another device of equal value ($250).
                    </Typography>
                    <Typography sx={{
                        color: "#fff",
                        fontFamily: "'Montserrat', Helvetica, Arial, Verdana, sans-serif",
                        fontWeight: 600,
                        fontSize: { xs: '12px', md: '12px', lg: '16px', xl: "16px" },
                        lineHeight: { xs: '16px', md: '16px', lg: '20px', xl: "20px" },
                    }}>
                        **With $99.00 Customer Installation Charge and purchase of alarm monitoring services. Early termination fees apply. See important Terms and Conditions to this offer below.
                    </Typography>
                </Box>
            </Container>
        </>
    );
}
