export const HIDE_PARTNER_LOGO = [
    '/partners/pw-home-security',
    '/partners/porch',
    '/partners/my-vu',

    '/partners/marketingessentials',
    '/partners/ahp',
    '/partners/y-consulting',
    '/partners/key-me-kiosk',
    '/partners/key-me',
    '/partners/pmh-affiliate',
    '/partners/phh-mortgage',
    '/partners/pmh-sold',
    '/partners/my-home-group',
    '/partners/marketing-essentials',
];

// {HIDE_PARTNER_LOGO.includes(pathname) ? null : <PartnerLogo partnerId={partnerInfo?.partnerId} campaignId={partnerInfo?.campaignId}></PartnerLogo>}

export const BUTTON_LOGO_BANNER = [
    '/partners/marketingessentials',
    '/partners/qualitybuilder',
    '/partners/selectquotedigitalreferral',
    '/partners/freedomcsr',
    '/partners/freedomapp',
    '/partners/freedomacp',
    '/partners/freedomrehash',
    '/partners/myprotectionhero',
    '/partners/freedommortgageprofessionals',
    '/partners/selectquote',
    '/partners/freedompartners',
    '/partners/roundpointmortgage'
    , '/partners/freedomwelcomeemail'
    , '/partners/freedomcustomervideo'
    , '/partners/freedomwelcomekit'
    , '/partners/roundpointmarketplace'
    , '/partners/roundpoint'
    , '/partners/freedomstatement'
    , '/partners/atlanticbay'
    , '/partners/freedomblog'
    , '/partners/yourfreedommortgage'
    , '/partners/yourfreedomoffer'
    , '/partners/freedomnurture'
    , '/partners/freedomemployeeoffer'
    , '/partners/freedomemployee'
    , '/partners/freedomrewards'
    , '/partners/freedom'
    , '/partners/bethanywhitegroup'
    , '/partners/freedomonboarding'
    , '/partners/freedommobile'
    , '/partners/freedommortgagepopup'
    , '/partners/prequalfreedomjourney'
    , '/partners/freedomcustomeradvocate'
    , '/partners/kinecta'
    , '/partners/freedomsavings'
    , '/partners/pmhaffiliate'
    , '/partners/jocelyn-rivard'];

export const SHOW_AGENT_NAME = ['3733', '2913', '2604', '3007',];

export const HIDE_ADRESS_FIELD = ['2751', '1702'];

export const SHOW_CRM_ID = ['3007',];

export const PASSWORD_PROTECTED = ['/partners/y-consulting',];