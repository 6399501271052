import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./index.scss";

import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import { Box, Container, Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

export default function SwiperCarousel(props: any) {
	if (props.type === "employeeReview") {
		return (
			<Swiper
				loop={true}
				navigation={true}
				pagination={true}
				keyboard={true}
				modules={[Navigation, Pagination, Mousewheel, Keyboard]}
				className={props.type + "-carousel"}
			>
				{props.slides.map((slide: any, index: any) => (
					<SwiperSlide key={index} className={props.type + "-swiper-slide"}>
						<Container
							sx={{
								display: "flex",
								flexDirection: {
									xxs: "column-reverse",
									md: "initial",
								},
								padding: { xxs: "0 50px", sm: "0 100px", lg: "0 24px" },
								height: "100%",
							}}
						>
							<Box
								sx={{
									width: { xxs: "100%", md: "50%" },
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexDirection: "column",
								}}
							>
								<Box sx={{ maxWidth: "430px", marginRight: { lg: "10%" } }}>
									<Typography
										sx={{
											fontFamily: "PalanquinDark",
											fontSize: "5rem",
											color: "#80ced7",
											lineHeight: ".25",
											marginTop: "1rem",
											marginBottom: { xxs: "2rem" },
											transform: "rotateY(180deg) rotatex(180deg)",
										}}
									>
										"
									</Typography>
									<Typography
										className="swiper-carousel-content"
										sx={{
											color: "#fff",
											fontStyle: "italic",
											fontWeight: "700",
											fontSize: "1.25rem",
										}}
									>
										{slide.content}
										<span className="quotation-custom">"</span>
									</Typography>
									<Divider
										sx={{
											backgroundColor: "#80ced7",
											width: "115px",
											maxWidth: "115px",
											margin: "auto",
											height: "6px",
											marginTop: "13px",
											marginBottom: "13px",
										}}
									></Divider>
									<Typography sx={{ color: "#fff" }}>
										-{slide.author}
									</Typography>
								</Box>
							</Box>
							<Box
								sx={{
									width: { xxs: "100%", sm: "80%", md: "50%" },
									margin: "auto",
									height: "100%",
								}}
							>
								<Box
									component="img"
									alt="The house from the offer."
									src={slide.image}
									sx={{ width: "100%" }}
								/>
							</Box>
						</Container>
					</SwiperSlide>
				))}
			</Swiper>
		);
	}
	if (props.type === "paddioPartnerPage") {
		return (
			<Swiper
				loop={true}
				navigation={false}
				pagination={true}
				keyboard={true}
				modules={[Navigation, Pagination, Mousewheel, Keyboard]}
				className={props.type + "-carousel"}
			>
				{props.slides.map((slide: any, index: any) => (
					<SwiperSlide
						key={index}
						className={props.type + "-swiper-slide" + " cursor-pointer"}
					>
						<Grid
							container
							sx={{ maxWidth: "1200px", margin: "auto", padding: "32px 0", minHeight: { xs: '75vh', sm: 'auto' }, maxHeight: { xs: '75vh', sm: 'auto' }, height: { xs: '75vh', sm: 'auto' }, }}
						>
							<Grid
								item
								xs={12}
								sm={6}
								sx={{ minHeight: { xs: '50%', sm: 'auto' }, maxHeight: { xs: '50%', sm: 'auto' }, height: '50%', }}
								className="paddio-partner-text-container"
							>
								<Box sx={{}}>
									<Box className="paddio-partner-text-header">
										{slide.header}
									</Box>
									<Box className="paddio-partner-text-body">
										{slide.content}
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}
								sx={{
									minHeight: { xs: '50%', sm: 'auto' },
									maxHeight: { xs: '50%', sm: 'auto' },
									display: { xs: 'flex', sx: 'initial' },
									alignItems: { xs: 'center', sx: 'initial' },
									justifyContent: { xs: 'center', sx: 'initial' },
								}}>
								<Box
									component="img"
									alt="The house from the offer."
									src={slide.image}
									sx={{ width: { xs: 'auto', sm: "100%" }, maxHeight: '100%', maxWidth: '100%' }}
								/>
							</Grid>
						</Grid>

						<Container
							sx={{
								display: "flex",
								flexDirection: {
									xxs: "column-reverse",
									md: "initial",
								},
								padding: { xxs: "0 50px", sm: "0 100px", lg: "0 24px" },
								height: "100%",
							}}
						></Container>
					</SwiperSlide>
				))}
			</Swiper>
		);
	}
	if (props.type === "nerdwallet") {
		return (
			<Swiper
				loop={true}
				navigation={false}
				autoplay={{ delay: 5000 }}
				pagination={{ clickable: true }}
				modules={[Navigation, Pagination, Mousewheel, Keyboard]}
				spaceBetween={0} // Remove default spacing between slides
				slidesPerView={1} // Set slidesPerView to 1 to ensure full-width images
				style={{ padding: 0 }} // Set container padding to 0
			>
				{props.slides.map((slide: any, index: number) => (
					<SwiperSlide key={index} className="nerdwallet-swiper-slide">
						<Box
							component="img"
							sx={{
								width: { xs: "80%", md: "25%" },
								height: { xs: "20%", md: "80%" },
								alignContent: "center",
							}}
							alt="The house from the offer."
							src={slide?.image}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		);
	} else
		return (
			<Swiper
				loop={true}
				navigation={true}
				pagination={true}
				keyboard={true}
				mousewheel={true}
				modules={[Navigation, Pagination, Mousewheel, Keyboard]}
				className="mySwiper"
			>
				{props.slides.map((slide: any, index: any) => (
					<SwiperSlide key={index} className="mySwiper-slide">
						{slide.content}
					</SwiperSlide>
				))}
			</Swiper>
		);
}
