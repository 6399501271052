import './index.scss';
import { Helmet } from 'react-helmet';

export default function Agents() {
  return (
    <>
      <Helmet>
        <title>Contact Us | Safe Haven Security</title>
        <meta
          name="description"
          content="Get in touch with Safe Haven Security. We're here to answer your questions and help you find the right security solution for your needs."
        />
        <meta
          name="keywords"
          content="Safe Haven Security, contact us, security solutions, customer service, questions, inquiries, info@mysafehaven.com, 877-842-0818"
        />
      </Helmet>

    </>
  );
}
