import React, { useState, useEffect } from 'react';
import './index.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Button, Link } from '@mui/material';
import topBgCropped from "../../../../../Assets/Partner/Paddio/banner-img-1136x666-cropped.jpg";
import CircularProgress from '@mui/material/CircularProgress';

import { Box, Container, Stack } from '@mui/system';
import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; // For form validation
import yardSign from "../../../../../Assets/Partner/Paddio/yard-sign-1-img.jpg";
import planIcons from "../../../../../Assets/Partner/Paddio/plans-icons.png";
import CompanyLogo from '../../../../../Components/Header/CompanyLogo';
import icon1 from "../../../../../Assets/Partner/Paddio/plans-individual-icon-1.jpg";
import icon2 from "../../../../../Assets/Partner/Paddio/plans-individual-icon-2.jpg";
import icon3 from "../../../../../Assets/Partner/Paddio/plans-individual-icon-3.jpg";
import icon4 from "../../../../../Assets/Partner/Paddio/plans-individual-icon-4.jpg";
import icon5 from "../../../../../Assets/Partner/Paddio/plans-individual-icon-5.jpg";
import vuVoucher from "../../../../../Assets/Templates/1000-instant-rebate-VU-2.png";
import army from "../../../../../Assets/Templates/army.jpg";
import "./index.scss";
import SwiperCarousel from '../../../../../Components/SwiperCarousel';
import * as Constants from '../../../../../Constants/Pages';
import { isMobile, isTablet } from 'react-device-detect';
import PartnerLogo from '../../../../../Components/PartnerLogo';
import useFetch from 'use-http';
import { HIDE_PARTNER_LOGO } from '../../Constants';
import LoadingButton from '@mui/lab/LoadingButton';
import { STATE_LIST } from '../../../../../Constants';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import partnerLogo from "../../../../../Assets/Partner/FreedomMortgage/Freedom-logo-Blue-writing-1-300x90-1.png";
import roundPointFam from "../../../../../Assets/Templates/roundpoint-1.png";
import roundpointVoucher from "../../../../../Assets/Templates/roundpoint-4.png";
import panelBillboard1 from "../../../../../Assets/Templates/panel-image-cropped.png";
import panelBillboard2 from "../../../../../Assets/Templates/panel-image.png";
import panel from "../../../../../Assets/Templates/roundpoint-55.png";
import familyPic from "../../../../../Assets/Partner/FreedomMortgage/Stock_HappyFamilyHouseADTsign.png";
import installTech from "../../../../../Assets/Partner/FreedomMortgage/Install-Tech.jpg";
import voucher500 from "../../../../../Assets/Templates/voucher-500.png";
import locksmithsLogo from "../../../../../Assets/Templates/Keyme3.jpg";
import keymeCallBtn from "../../../../../Assets/Templates/KeyMe-2.jpg";
import control from "../../../../../Assets/Partner/FreedomMortgage/Control.jpg";
import Billboard from '../Billboard';
import AffiliateFormOne from '../../../../../Components/Forms/Affiliate';
import LogoBanner from '../LogoBanner';
import PartnerFooter from '../PartnerFooter';

interface GrayFormBannerProps {
    partnerPhone: string;
    partnerInfo: PartnerInfo;
}

export default function GrayFormBanner({
    partnerInfo,
    partnerPhone,
}: GrayFormBannerProps) {
    const navigate = useNavigate();
    const handleNavigate = (route: string) => navigate(`${route}`);
    const [leadCreationStatus, setLeadCreationStatus] = useState<boolean | null>(null);
    const [submitBtnText, setSubmitBtnText] = useState<string>('GET A FREE QUOTE');

    let apiKey = process.env.REACT_APP_API__ACCESS_KEY
        ? process.env.REACT_APP_API__ACCESS_KEY
        : '';

    const options = {
        headers: {
            'Content-Type': 'application/json',
            Connection: 'keep-alive',
            'Accept-Encoding': 'gzip, deflate, br',
            'X-API-Key': apiKey,
        },
        cacheLife: 1000,
    };

    const { post, response, loading } = useFetch(
        process.env.REACT_APP_API,
        options,
    );

    const initialValues = {
        //TODO: update this to work off of a passed-in value
        miniForm: partnerInfo.CampaignId === '3723' || partnerInfo.CampaignId === '3130' || partnerInfo.CampaignId === '3149' || partnerInfo.CampaignId === '3684' || partnerInfo.CampaignId === '1920',
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        phoneCell: "",
        ssn: "",
        dob: "",
        email: "",
        emailConfirm: "",
        repName: "",
        repId: "",
        notes: "",
        package: "",
        consent: false,
    };

    const validationSchema = Yup.object({
        miniForm: Yup.boolean(),
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),

        address: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        city: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        state: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        zip: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        phone: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        phoneCell: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        repName: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        repPhone: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        repId: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        notes: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
        consent: Yup.string().when('miniForm', {
            is: false,
            then: () => Yup.string().required('Required'),
            otherwise: () => Yup.string().nullable()
        }),
    });

    const openInNewTab = (url: string) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <Container
            maxWidth={false}
            className=""
            sx={{ backgroundColor: "rgb(237, 237, 237)", paddingBottom: '12px' }}
        >
            <Stack
                direction={"column"}
                sx={{
                    paddingTop: "24px",
                    paddingBottom: "24px",
                    alignItems: "center",
                }}
            >
                <Typography
                    className="white-title"
                    sx={{
                        color: "#545859",
                        font: {
                            xs: "normal 800 24px/34px Montserrat,Helvetica,Arial,Verdana,sans-serif",
                            md: "normal 800 44px/54px Montserrat,Helvetica,Arial,Verdana,sans-serif",
                        },
                        maxWidth: { xs: "300px", md: "650px" },
                        marginTop: "16px",
                        marginBottom: { xs: "16px" },
                    }}
                >
                    Get a FREE quote
                </Typography>

                <Typography
                    sx={{
                        textAlign: "center",
                        maxWidth: { xs: "300px", sm: "650px", md: "650px" },
                        marginBottom: { xs: "16px", md: "48px" },
                    }}
                >
                    Call{" "}
                    <Link sx={{ textDecoration: "none" }} href={`tel:${partnerPhone}`}>
                        {partnerPhone}
                    </Link>{" "}
                    or fill out the form below and a Safe Haven Security Specialist will
                    contact you, from time to time, about security and smart home
                    offers.
                </Typography>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        try {
                            const submissionData = {
                                ...values,
                                partnerCompanyId: partnerInfo.PartnerId,
                                partnerCampaignId: partnerInfo.CampaignId,
                            };

                            const postResponse: ApiResponseModel = await post("/Partner/CreateZohoLeadByPartnerId", submissionData);

                            if (postResponse.code === 'OK') {
                                const parsedContent = JSON.parse(postResponse.content);

                                const newLeadId = parsedContent.details.output;
                                const userMessage = JSON.parse(parsedContent.details.userMessage[0]);

                                setLeadCreationStatus(true);

                                console.log("New ID: " + newLeadId);

                                navigate('/partners/confirmation', { state: { newLeadId, partnerInfo } });
                            } else {
                                console.log("Response code not OK: " + postResponse.code);
                                setLeadCreationStatus(false);
                            }

                            console.log("Request sent successfully:", postResponse);
                            resetForm();
                        } catch (error) {
                            console.error("Error sending request:", error);
                        } finally {
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, touched, isSubmitting }) => (
                        <Form>
                            <Box className="fields-container perfect-vision" sx={{}}>
                                <Field
                                    as={TextField}
                                    className="left-field right-field nra-form-field freedom-mortgage-field"
                                    sx={{ width: { xs: 'calc(100% - 10px)', sm: 'calc(50% - 10px)', md: 'calc(25% - 10px)' } }}
                                    name="firstName"
                                    label="First Name"
                                    error={touched.firstName && Boolean(errors.firstName)}
                                    helperText={touched.firstName && errors.firstName}
                                />

                                <Field
                                    as={TextField}
                                    className="left-field right-field nra-form-field freedom-mortgage-field"
                                    sx={{ width: { xs: 'calc(100% - 10px)', sm: 'calc(50% - 10px)', md: 'calc(25% - 10px)' } }}
                                    name="email"
                                    label="Email"
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />

                                <Field
                                    as={TextField}
                                    className="left-field right-field nra-form-field freedom-mortgage-field"
                                    sx={{ width: { xs: 'calc(100% - 10px)', sm: 'calc(50% - 10px)', md: 'calc(25% - 10px)' } }}
                                    name="phone"
                                    label="Phone - Main"
                                    error={touched.phone && Boolean(errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                />

                                <Field
                                    as={TextField}
                                    className="left-field right-field nra-form-field freedom-mortgage-field"
                                    sx={{ width: { xs: 'calc(100% - 10px)', sm: 'calc(50% - 10px)', md: 'calc(25% - 10px)' } }}
                                    name="zip"
                                    label="Zip"
                                    error={touched.zip && Boolean(errors.zip)}
                                    helperText={touched.zip && errors.zip}
                                />

                                <Box sx={{ textAlign: "center", width: "100%" }}>
                                    <Typography sx={{
                                        textAlign: 'center',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        maxWidth: '950px',
                                        margin: '16px auto auto auto',
                                    }}>
                                        By clicking ‘{submitBtnText},’ I consent to being contacted by Safe Haven Security Services, LLC, Safe Haven Solar, LLC, or All Access Service, LLC regarding products and services via live, automated, or prerecorded telephone calls, text messages*, or emails, even if I’m on a Do Not Call list. Consent is not a condition of purchase. I can revoke this consent by emailing dnc@mysafehaven.com.
                                    </Typography>
                                    <LoadingButton
                                        className="blue-rounded-btn"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                        loadingIndicator={<CircularProgress className='loading-button-indicator' size={24} />}
                                        disabled={isSubmitting}
                                        sx={{
                                            fontWeight: 700,
                                            minHeight: "50px!important",
                                            marginTop: "16px!important",
                                        }}
                                    >
                                        {isSubmitting ? 'Submitting...' : 'GET A FREE QUOTE'}
                                    </LoadingButton>
                                    <Typography sx={{
                                        textAlign: 'center',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        maxWidth: '950px',
                                        margin: '16px auto auto auto',
                                    }}>
                                        * Standard message and data rates may apply to text messages. You may opt out of receiving further text messages at any time by replying STOP.
                                        <br></br>
                                        † Your information is collected and used in accordance with Safe Haven’s {" "}
                                        <Link
                                            sx={{ textDecoration: "underline" }}
                                            href="https://mysafehaven.com/legal/privacy"
                                            onMouseDown={(event) => {
                                                if (event.button === 1) {
                                                    openInNewTab(`https://mysafehaven.com/legal/privacy`);
                                                }
                                            }}>
                                            Privacy Policy
                                        </Link>.
                                    </Typography>
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Stack>
        </Container>
    );
}
