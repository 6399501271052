import { Link, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import './index.scss';

export default function PartnerFooter() {
    const openInNewTab = (url: string) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <Box
            sx={{
                backgroundColor: '#1260a8',
                borderTop: '1px solid black',
                color: '#ffffff',
            }}
        >
            <Box className='freedom-mortgage-grid-container' sx={{ padding: '3rem' }}>
                <Typography className='bottom-disclaimer-freedom-mortgage'>
                    Dealer License Numbers by State: AL-1493, AR- CMPY.0002629, AZ- ROC-332758,18404-0, CA-ACO8016, CT-HIC.0661825, DC-602521000002, DE-17-39, FL-EF20000905, GA-LVU406182, IA-AS-0158, C115438, IL-127.001386, LA-F1672, MA-8150 A1, SS-002127, MD-107-1696, MI-3601208054, MN-TS710056, MS-15019531, NC-1150-CSA, SP.FA/LV.28805, NH-064, NJ- 34BX00006600, NM-403946, NV-0079533, NY-12000077734, OK-AC1802, OR-234026, PA-PA040539, RI-4604, TSC8204, SC-BAC.13574, TN-2280, TX-B14839,ACR-2625584, UT-10999117-6501, VA-11-6402, WA-SAFEHHS791CH, WV-WV060562
                </Typography>
                <Typography className='' sx={{ marginTop: '12px', color: '#ffffff', font: 'normal 14px / 24px "Montserrat", Helvetica, Arial, Verdana, sans-serif !important', textAlign: 'center' }}>
                    + <Link sx={{ color: '#ffffff', textDecoration: 'underline' }} href="https://www.adt.com/about-adt/legal/residential-terms-and-conditions"
                        onMouseDown={(event) => {
                            if (event.button === 1) {
                                openInNewTab(`https://www.adt.com/about-adt/legal/residential-terms-and-conditions`);
                            }
                        }}>ADT Disclaimers</Link>
                    <br></br>
                    ++ <Link sx={{ color: '#ffffff', textDecoration: 'underline' }} href="https://www.adt.com/about-adt/legal/residential-terms-and-conditions"
                        onMouseDown={(event) => {
                            if (event.button === 1) {
                                openInNewTab(`https://www.adt.com/about-adt/legal/residential-terms-and-conditions`);
                            }
                        }}>ADT Disclaimers</Link>
                </Typography>
            </Box>
        </Box>
    );
}
